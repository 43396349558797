import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { convertToBgImage } from "gbimage-bridge"

import SEO from "../components/SEO"
import MapImage from "../components/mapImage"
import ContactForm from "../components/contactForm"
import ContactBanner from "../components/contactBanner"
import Hero, { HeroTitleDescription } from "../components/Hero"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import { GatsbyImage } from "gatsby-plugin-image"
import ExternalLink from "../components/ExternalLink"

const ContactPage = ({ data }) => {
  const { contactData, map } = data

  const exampleStaffData = [
    {
      name: "Rachel Butler",
      title: "Program Lead",
      email: "rbutler@windigo.on.ca",
      phone: "(807) 738-5812",
    },
    {
      name: "Staff member 2",
      email: "staff@example.com",
      phone: "(807) 555-5555",
    },
  ]

  // TO FIX

  const contactHeroFluid =
    contactData?.contactBanner?.asset?.gatsbyImageData || null

  const bgImage = convertToBgImage(contactHeroFluid)

  // const contactHeroFluid = null

  const contactMap = map.childImageSharp.gatsbyImageData

  const images = {
    contactHeroFluid: bgImage?.fluid || null,
    contactMap: contactMap,
  }

  const SubContainer = ({ children, className }) => (
    <div className={`flex flex-col w-full ${className}`}>{children}</div>
  )
  const StaffContainer = ({
    children,
    name = "",
    phone = "",
    email = "",
    title = "",
  }) => (
    <div className="grid mt-5 text-xl">
      <p className=" font-semibold ">{name}</p>
      {title && <p className="italic">{title}</p>}
      <div className="pl-1">
        {email && <a href={`mailto:${email}`}>{email}</a>}
        {phone && <p>{phone}</p>}
      </div>
    </div>
  )

  return (
    //pass custom header to Layout
    <Layout>
      <SEO title="Contact" />
      <Hero
        props={{
          heroFluid: images.contactHeroFluid,
          gridPosition:
            " justify-items-center lg:justify-items-end items-center",
          gridStyle: "place-items-center",
          className: "px-5 py-10",
        }}
      >
        <div className=" lg:mr-44">
          <h1 className="font-bold text-center mb-5">Contact Us</h1>
          <ContactForm className="bg-white bg-opacity-20 py-3 px-6 rounded" />
        </div>
      </Hero>

      <PageContentContainer>
        <BannerContainer color="bg-secondary" className=" text-primary py-20">
          <div id="contactInfoWrapper" className="flex flex-wrap mx-5">
            <div
              id="ContactDetails"
              className="text-primary justify-self-end grid gap-y-8 md:mr-10 mb-8 justify-items-center md:justify-start w-full  md:w-auto"
            >
              <SubContainer className="text-center md:text-left">
                <h2>W.I.N.K.S. Staff:</h2>
                {contactData.staff.map(staffMember => (
                  <StaffContainer
                    name={staffMember.name}
                    email={staffMember.email}
                    phone={staffMember.phone}
                    title={staffMember.role}
                  />
                ))}
              </SubContainer>
            </div>
            <div>
              <div className="text-2xl mb-10 text-center">
                <h2>Get in touch!</h2>
                <br />
                <p>(807) 738-5812 (Rachel)</p>
                <ExternalLink to="https://goo.gl/maps/8oz7uty6sPdjAgm17">
                  57 King St., Sioux Lookout, ON
                </ExternalLink>
              </div>
              <ExternalLink
                to="https://goo.gl/maps/8oz7uty6sPdjAgm17"
                className=""
              >
                <GatsbyImage
                  image={images.contactMap}
                  className=" rounded shadow"
                  alt=""
                />
              </ExternalLink>
              {/* <div className="font-bold text-primary text-2xl mt-10 text-center grid">
                <div className="text-red-700">24/7 Emergency Line:</div>
                <br />
                <a href="tel:+18075555555" className="underline mt-3 ">
                  (807) 555-5555
                </a>
              </div> */}
            </div>
          </div>
        </BannerContainer>
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    map: file(relativePath: { eq: "WINKS_Map.png" }) {
      id
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, width: 500, placeholder: BLURRED)
      }
    }
    contactData: sanityContactPage {
      staff {
        name
        phone
        email
        role
      }
      contactBanner {
        asset {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
          id
        }
      }
    }
  }
`

export default ContactPage
